<template>
  <v-dialog justify='center' :value="value" persistent width="650">
    <v-card color="fill" flat class='overflow-hidden'>
      <v-card-title :class="title">Ad Clone</v-card-title>
      <v-row dense justify="center">
            <v-col cols='7'>
              <v-text-field :class="body" :rules="adNameRules" v-model="ad.ad_name"
                background-color="input" label="New Ad Name" autocomplete="off" dense outlined clearable/>
            </v-col>
          </v-row>
      <v-row dense justify="center">
            <v-col cols='4'>
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" bottom nudge-top="25" :nudge-left="10"
                transition="scale-y-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :class="body" :value="formattedStart" outlined dense background-color="input"
                    label="Start Date" prepend-inner-icon="mdi-calendar" :rules="[v => !!v ||  'Start Date Required']"
                    readonly v-bind="attrs" v-on="on" clearable  @click:clear="resetEndDate"/>
                </template>
                <v-date-picker :show-current="false" color="accent"
                   v-model="ad.start_date" @input="menu1 = false" no-title :max="ad.end_date" />
              </v-menu>
            </v-col>
            <v-col cols='4'>
              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" bottom nudge-top="25" :nudge-left="10"
                transition="scale-y-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :class="body" :value="formattedEnd" outlined dense background-color="input"
                    label="End Date" prepend-inner-icon="mdi-calendar" :rules="[v => !!v ||  'End Date Required']"
                    readonly v-bind="attrs" v-on="on" clearable  @click:clear="resetEndDate"/>
                </template>
                <v-date-picker :show-current="false" color="accent"
                   v-model="ad.end_date" @input="menu2 = false" no-title :min="ad.start_date"/>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense justify="center">
            <v-col cols='8'>
              <v-textarea :class="body" outlined dense background-color="input" label="Notes (Optional)" no-resize
                rows="3" v-model="ad.note" clearable/>
            </v-col>
          </v-row>
      <v-toolbar flat color="fill">
        <v-spacer/>
        <v-btn :class="body" text @click="$emit('update:value', false)">Close</v-btn>
        <v-btn :class="body" class="success white--text" @click.stop='cloneAd'>Copy</v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { displayHelpers } from '@/mixins/display-helpers'

export default {
  name: 'AdCopy',

  mixins: [displayHelpers],

  props: [
    'value',
    'selectedAd',
  ],

  data () {
    return {
      adNameRules: [
        value => !!value || 'Name is Required',
        value => (value || '').length <= 52 || '52 characters maximum',
      ],
      ad:{
        ad_name: '',
        start_date: '',
        end_date: '',
        note:''
      },
      requiredMessage: 'Field Required',
      menu1: false,
      menu2: false,
    }
  },

  computed: {
    formattedStart () {
      return this.ad.start_date ? this.moment(this.ad.start_date).format(this.$dateConfig.date_display) : ''
    },

    formattedEnd () {
      return this.ad.end_date ? this.moment(this.ad.end_date).format(this.$dateConfig.date_display) : ''
    },
  },

  methods: {
    resetStartDate(){
      this.ad.start_date = ''
    },
    resetEndDate(){
      this.ad.end_date = ''
    },
    async cloneAd(){
      if(this.ad.ad_name.length != 0)
      {
        try {
        await this.$Ads.clone(this.selectedAd.id, this.ad)
        this.$emit('refresh')
        this.$emit('update:value', false)
        this.$store.dispatch('setSnackbar', { status: 'success', text: `Copy In Progress` })
      }
      catch (err) {        
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Shuttle failed due to ${err?.response?.data?.message || err.message}` })
      }
      }
      else {
        this.$store.dispatch('setSnackbar', { status: 'error', text: `Name Must Not Be Empty` })
      }
    }
    
  }
}
</script>
<style scoped>
.card {
  overflow-x:hidden;
}

</style>
